<template>
  <div class="Register">
    <AuthLayoutContent>
      <template v-slot:left>
        <div class="left">
          <div class="row">
            <div class="col-md-12">
              <h1>
                {{ $t("Tell us a little more about yourself") }}
              </h1>
              <p class="text-muted">
                Let’s first off start with some info about you, and how we may address
                you.
              </p>
            </div>
          </div>
          <ValidationObserver
            ref="observer-step1"
            v-slot="{ handleSubmit }"
            slim
            v-if="step == 1"
          >
            <b-form id="step1-form" @submit.prevent="handleSubmit(step1Submit)">
              <div class="row">
                <TextInput
                  class="col-md-6 py-0"
                  name="first_name"
                  rules="required"
                  :label="$t('First name')"
                  v-model="form.first_name"
                ></TextInput>
                <TextInput
                  class="col-md-6 py-0"
                  name="last_name"
                  rules="required"
                  :label="$t('Last name')"
                  v-model="form.last_name"
                ></TextInput>
                <SelectInput
                  class="col-md-12 py-0"
                  name="locale"
                  rules="required"
                  v-model="form.locale"
                  :label="$t('In which language do you want to receive email?')"
                >
                  <option value="nl">Dutch</option>
                  <option value="en">English</option>
                </SelectInput>
              </div>
              <slot name="formfooter">
                <div class="row">
                  <div class="col-md-12">
                    <BButton variant="primary" block type="submit">
                      {{ $t("Next step") }}
                    </BButton>
                  </div>
                </div>
              </slot>
            </b-form>
          </ValidationObserver>

          <Spinner v-if="step == 2" class="mb-5" />
          <ValidationObserver
            ref="observer"
            v-slot="{ handleSubmit }"
            slim
            v-if="step == 3"
          >
            <b-form @submit.prevent="handleSubmit(onSubmit)" autocomplete="off">
              <div class="row">
                <TextInput
                  class="col-md-12 py-0"
                  name="email"
                  rules="required"
                  :label="$t('Email')"
                  v-model="form.email"
                  autocomplete="off"
                ></TextInput>

                <PasswordInput
                  type="password"
                  class="col-md-12 pt-0"
                  name="password"
                  rules="required"
                  :label="$t('Password')"
                  v-model="form.password"
                />

                <div class="col-md-12">
                  <Checkbox
                    name="agree_terms_condition"
                    v-model="form.agree_terms_condition"
                  >
                    I agree to the
                    <a
                      href="https://www.eerlijktehuur.nl/algemene-voorwaarden"
                      target="_blank"
                    >
                      Terms and conditions
                    </a>
                    and
                    <a
                      href="https://www.eerlijktehuur.nl/privacyverklaring/"
                      target="_blank"
                    >
                      privacy statement
                    </a>
                  </Checkbox>
                </div>
                <!-- <div class="col-md-12 d-flex" v-if="form.password">
                  <div class="mr-1">{{ $t("Password strength") }} :</div>
                  <div class="text-success" v-if="passwordStrength == 'strong'">
                    {{ $t("Strong") }}
                  </div>
                  <div class="text-primary" v-if="passwordStrength == 'medium'">
                    {{ $t("Medium") }}
                  </div>
                  <div class="text-danger" v-if="passwordStrength == 'weak'">
                    {{ $t("Weak") }}
                  </div>
                </div> -->
                <div class="col-md-12 py-0" v-if="passwordTips.length">
                  <ul>
                    <li v-for="(tip, key) in passwordTips" :key="key" class="text-info">
                      {{ tip }}
                    </li>
                  </ul>
                </div>
                <div class="col-md-12 pt-0" v-if="getErrors.auth">
                  <div class="alert alert-danger mb-0" v-if="getErrors.auth">
                    {{ getErrors.auth }}
                  </div>
                </div>
              </div>
              <slot name="formfooter">
                <div class="row">
                  <div class="col-md-12">
                    <BButton
                      variant="primary"
                      block
                      type="submit"
                      :disabled="getLoadings.auth"
                    >
                      <b-spinner
                        v-if="getLoadings.auth"
                        small
                        label="Spinning"
                        variant="white"
                      ></b-spinner>
                      {{ $t("Register") }}
                    </BButton>
                  </div>
                </div>
              </slot>
            </b-form>
          </ValidationObserver>

          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <span>{{ $t("Already have an account?") }}</span>
                <routerLink
                  class="createAccount mt-3"
                  :to="{ name: 'BrokerLogin' }"
                  v-if="type == 'broker'"
                >
                  <strong>
                    {{ $t("Login here") }}
                  </strong>
                </routerLink>
                <routerLink
                  class="createAccount mt-3"
                  :to="{ name: 'UserLogin' }"
                  v-if="type == 'user'"
                >
                  <strong>
                    {{ $t("Login here") }}
                  </strong>
                </routerLink>
              </div>
            </div>
          </div>
          <!-- <Auth
            cardClass="Register__card"
            :cardHeading="cardHeading"
            :cardSubheading="subheading"
          >
            <Form
              :formFields="formFields"
              :stacked="false"
              @on-submit="onSubmit"
              :error="getErrors.auth"
            >
              <template v-slot:formfooter>
                <div class="row">
                  <div class="col-md-12">
                    <button
                      class="btn btn-primary btn-block"
                      :disabled="getLoadings.auth"
                    >
                      {{ $t("register") }}
                      <b-spinner
                        v-if="getLoadings.auth"
                        small
                        label="Spinning"
                        variant="white"
                      ></b-spinner>
                    </button>
                  </div>
                </div>
              </template>
            </Form>
          </Auth> -->
        </div>
      </template>
      <template v-slot:right>
        <RightSection v-if="type == 'user'" :img="user_img" />
        <RightSection v-else :img="img" />
      </template>
    </AuthLayoutContent>
  </div>
</template>

<script>
import RightSection from "../common/RightSection.vue";
import AuthLayoutContent from "../common/AuthLayoutContent.vue";
import { mapActions, mapGetters } from "vuex";
import TextInput from "../ui/form/controls/TextInput.vue";
import SelectInput from "../ui/form/controls/SelectInput.vue";
import { ValidationObserver } from "vee-validate";
import PasswordInput from "../ui/form/controls/PasswordInput.vue";
import Spinner from "../ui/Spinner.vue";
import Checkbox from "../ui/form/controls/Checkbox.vue";

export default {
  props: [
    "RegisterBtnText",
    "formFields",
    "authHeading",
    "cardHeading",
    "externalClass",
    "type",
    "otherUserLink",
    "otherUserText",
    "subheading",
  ],
  components: {
    RightSection,
    AuthLayoutContent,
    TextInput,
    SelectInput,
    ValidationObserver,
    PasswordInput,
    Spinner,
    Checkbox,
  },
  data() {
    return {
      img: require("../../assets/images/auth/register.svg"),
      user_img: require("../../assets/images/user_register.svg"),
      form: {
        first_name: "",
        last_name: "",
        locale: "nl",
        email: "",
        password: "",
        agree_terms_condition: false,
      },
      tips: "",
      step: 1,
    };
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters(["getLocale"]),
    passwordTips() {
      let strength = 0;
      let tips = [];
      let password = this.form.password;

      if (!this.form.password) {
        return [];
      }

      if (password.length < 8) {
        tips.push("Password should be minimun 10 characters.");
      } else {
        strength += 1;
      }

      // Check for mixed case
      if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
        strength += 1;
      } else {
        tips.push("Use both lowercase and uppercase letters.");
      }

      // Check for numbers
      if (password.match(/\d/)) {
        strength += 1;
      } else {
        tips.push("Include at least one number.");
      }
      // Check for special characters
      if (password.match(/[^a-zA-Z\d]/)) {
        strength += 1;
      } else {
        tips.push("Include at least one special character.");
      }

      console.log("strength", strength);
      return tips;
      // if (strength < 2) {
      //   return "weak";
      // } else if (strength === 2) {
      //   return "medium";
      // } else if (strength === 3) {
      //   return "strong";
      // } else {
      //   return "strong";
      // }
    },
  },
  methods: {
    ...mapActions("auth", ["auth"]),
    step1Submit() {
      this.step++;
      setTimeout(() => {
        this.step++;
      }, 1000);
    },
    async onSubmit() {
      this.form.type = this.type;
      this.form.ref_listing = this.$route.query.ref_listing;

      if (this.step == 1) {
        this.step++;
        return;
      }

      await this.auth({
        body: this.form,
        url: "/register",
      });

      this.$router.push({ name: "Registrationcompleted" });
    },
  },
};
</script>

<style lang="scss">
.Register {
  height: 100%;

  &__card {
    max-width: 429px;

    @include xs {
      max-width: 90%;
    }
  }

  &__alreadyaccount {
    color: $alreadyAccountTextColor;
  }

  &__btn {
    box-shadow: 0px 4px 7px rgba(0, 129, 31, 0.45);
  }
}

.Register__card {
  .col-12 {
    padding-top: 0px !important;
  }
}

.left {
  margin: auto;
  max-width: 500px;
  @include xs {
    margin: 0;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
