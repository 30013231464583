<template>
  <ValidationProvider
    :vid="name"
    :name="name"
    :rules="{ required: { allowFalse: false } }"
    v-slot="{ valid, errors }"
  >
    <b-form-group>
      <b-form-checkbox
        placeholder=""
        :class="customClass"
        :checked="value"
        v-on:change="onInput"
        v-bind="$attrs"
        :state="errors[0] ? false : valid && validate ? true : null"
      >
        <slot />
      </b-form-checkbox>
      <b-form-invalid-feedback id="inputLiveFeedback">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import inputMixin from "@/mixins/inputMixin";
export default {
  mixins: [inputMixin],
};
</script>

<style lang="scss" scoped></style>
