<template>
  <Register
    :RegisterBtnText="RegisterBtnText"
    :formFields="formFields"
    :authHeading="$t('myProfile')"
    :cardHeading="$t('register.asUser')"
    :subheading="$t('register.userSubheading')"
    type="user"
    otherUserLink="/broker/register"
    :otherUserText="$t('broker.register.heading')"
  />
</template>

<script>
import Register from "@/components/Auth/Register.vue";
export default {
  components: {
    Register,
  },
  data() {
    return {
      RegisterBtnText: "Register",

      formFields: [
        {
          component: "TextInput",
          name: "first_name",
          rules: "required",
          placeholder: this.$t("First Name"),
          label: this.$t("First Name"),
          colClasses: "col-6 pb-0",
        },
        {
          component: "TextInput",
          name: "last_name",
          rules: "required",
          placeholder: this.$t("Last Name"),
          label: this.$t("Last Name"),
          colClasses: "col-6 pb-0",
        },
        {
          component: "TextInput",
          name: "email",
          rules: "required|email",
          placeholder: this.$t("emailPlaceholder"),
          label: this.$t("emailPlaceholder"),
          colClasses: "col-12 pb-0",
        },
        {
          component: "TextInput",
          name: "password",
          type: "password",
          placeholder: this.$t("password"),
          label: this.$t("password"),
          rules: "required|min:8|max:20",
          colClasses: "col-12 pb-0",
        },
      ],
    };
  },
};
</script>

<style lang="scss"></style>
